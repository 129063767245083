/* eslint-env node */
/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

import '../utils/version_checker';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'foundation-sites';
import $ from 'jquery';

Rails.start();
Turbolinks.start();

// Require syntax is required, and this must come after Turbolinks.start()
require('@client-side-validations/client-side-validations');

// Foundation
$(document).on('turbolinks:load', () => {
    $(document).foundation();
});

// React
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);// Support component names relative to this directory: