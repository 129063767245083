import PACKAGE_JSON from '../../../package.json';

const dependencies = PACKAGE_JSON.dependencies;

/*
 * Use this file when upgrading or downgrading a package causes issues
 * that may be difficult to detect. Unfortunately. package.json does not
 * permit including comments, so this is a workaround to save any future
 * developer from inadvertently updating packages that break things in
 * subtle ways and spending hours and hours debugging the issue.
 */

const REACT_DOM = '^16.14.0';

class VersionChecker {
    static validatePackages() {
        if (dependencies['react-dom'].toString() !== REACT_DOM) {
            throw new Error(`Wrong react-dom version. Expected: ${REACT_DOM}. Actual: ${dependencies['react-dom']}.
      React DOM v18 and higher causes issues in the SearchDialog.jsx. Specifically, the onSubmit and onClick React
      actions no longer trigger, and submitting the form will refresh the entire page instead of applying
      the filters. This is caused by Zurb Foundation detaching the virtual DOM inside "reveal" modal dialogs
      and React is no longer able to process any callbacks the moment the form is submitted. Upgrading to
      React v18 will involve rewriting all modal dialogs that use Zurb Foundation.`);
        }
    }
}

VersionChecker.validatePackages();